import React from "react";
import Star from "../../../../static/images/star.png";
const RatingReview = (props: any) => {
    const { title, subtitle } = props;
    return (
        <>
            <div className="container mx-auto px-4">
                <section className="internet-section4">                
                    <h2 className="h2 text-secondary text-center">{title}</h2>
                    <p className="p2 text-black text-center mb-12">{subtitle}</p>
                    <div className="max-width1000 grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                        <div className="internet-section4__box">
                            <h4 className="h4">Angela, Halifax, Nova Scotia</h4>
                            <div className="star-row">
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                            </div>
                            <p className="p2">Too much to rave about, and not enough time in the day. In the lead up, I heard nothing but good things about Purple Cow from our clients and friends of friends, and word of mouth goes far around here. We jumped the fence, and the pastures really are greener on this side.</p>
                        </div>
                        <div className="internet-section4__box">
                            <h4 className="h4">Angela, Halifax, Nova Scotia</h4>
                            <div className="star-row">
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                            </div>
                            <p className="p2">Too much to rave about, and not enough time in the day. In the lead up, I heard nothing but good things about Purple Cow from our clients and friends of friends, and word of mouth goes far around here. We jumped the fence, and the pastures really are greener on this side.</p>
                        </div>
                        <div className="internet-section4__box">
                            <h4 className="h4">Angela, Halifax, Nova Scotia</h4>
                            <div className="star-row">
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                            </div>
                            <p className="p2">Too much to rave about, and not enough time in the day. In the lead up, I heard nothing but good things about Purple Cow from our clients and friends of friends, and word of mouth goes far around here. We jumped the fence, and the pastures really are greener on this side.</p>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default RatingReview
